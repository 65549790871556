import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { filter, first, map, includes } from 'lodash'
import {
    useManagerBase,
} from '@docpace/shared-react-hooks/useManagerBase'
import {
    useSortProviders,
    ExtendedProvider,
} from '@docpace/shared-react-hooks/useSortProviders'
import { EmptyPageMessage } from '@docpace/shared-react-components/EmptyPageMessage'
import managerRoutes from '@docpace/shared-react-routes/managerRoutes'
import { makeAppointmentStatusCountToday, ListSortDirection } from '@docpace/shared-ts-types'

export function Index() {
    const router = useRouter()
    const {
        isLoading: baseIsLoading,
        providers,
        manager,
        admin,
    } = useManagerBase()
    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    const { sortedProviders, selectedProviders, unselectedProviders } =
        useSortProviders({
            manager,
            providers,
            initialSortKey: 'APPOINTMENT_COUNT_TODAY',
            initialSortDirection: ListSortDirection.DESC,
            favoriteProviderIds: [],
        })

    useEffect(() => {
        let pageProviders: ExtendedProvider[] = null
        if (manager) {
            const providerIds = map(
                manager?.managerProviderPermissions?.nodes,
                'providerId'
            )
            pageProviders = filter(sortedProviders, ({ providerId }) =>
                includes(providerIds, providerId)
            )
        }
        if (admin) pageProviders = sortedProviders

        const providersWithAppointments = providers?.filter(provider=>{
            return makeAppointmentStatusCountToday({
                provider, manager
            }) > 0
        })

        const firstProvider = first(providersWithAppointments) 
            ?? first(pageProviders)

        if (firstProvider?.providerId) {
            router.replace(
                managerRoutes.ManagerProviderByIdIndex.href({
                    providerId: firstProvider?.providerId,
                })
            )
        } else if (manager || admin) {
            setIsInitialized(true)
        }
    }, [baseIsLoading])

    if(!isInitialized) return <EmptyPageMessage id='initializing-message' message={`Initializing practice...`} />

    if (
        manager?.managerProviderPermissions?.nodes?.length === 0
    ) {
        return (
            <EmptyPageMessage
                message={`It appears that you do not have access to any providers' schedules.`}
                messageLineTwo={`Please contact your administrator.`}
            />
        )
    }

    return <EmptyPageMessage message={`No active providers could be found.`} />
}

export default Index
